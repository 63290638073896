<template>
  <message-box
    v-model="dialog"
    scrollable
    title="Партнерское соглашение"
    max-width="80%"
  >
    <policy-text />
  </message-box>
</template>

<script>
  import dialogable from '@/mixins/dialogable.js'

  export default {
    components: {
      MessageBox: () => import('@/components/message-box/MessageBox.vue'),
      PolicyText: () => import('@/views/auth/components/PolicyText.vue'),
      
    },
    mixins: [dialogable],

    data () {
      return {

      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
